import Card from '@material-ui/core/Card';
import { t } from 'i18next';
import { useCallback, useState } from 'react';
import {
    Filter,
    FunctionField,
    SearchInput,
    SelectInput,
    useGetIdentity,
    useGetOne,
    useNotify,
    useRecordContext,
    useRefresh,
    useUpdate
} from 'react-admin';
import { DEFECTIVE_MATERIAL_STATES } from '../constants';
import '../style/components/defective-materials.scss';
import { arrayToChoices } from '../utils';
import { DefectiveMaterialEdit } from './DefectiveMaterials';
import { MaintenanceMaterialCreateDialog, MaintenanceMaterialEdit } from './MaintenanceMaterials';
import { PageHeader } from './PageHeader';
import { RangeLimiter } from './RangeLimiter';
import {
    AddButton,
    ButtonIcon,
    CSVExportButton,
    UpdateDefectiveMaterialButton,
    ViewButton
} from './react-admin-components/Buttons';
import { DefectiveMaterialUploadDialog, MyDialog } from './react-admin-components/Dialog';
import MyList from './react-admin-components/MyList';
import { MyTopToolbar } from './react-admin-components/MyTopToolbar';
import { TextFieldImage, TextFieldPink } from './react-admin-components/TextFields';

const MaintenanceActions = props => {
    return (
        <MyTopToolbar {...props}>
            <CSVExportButton
                {...props}
                exporterDatas={Object.values(props.data)}
                exportFilename={t('layout.maintenance')}
                exportFormatFunction={formatDataForExport}
            />
            <AddButton
                label={t('defective-materials.declare-specific-anomaly')}
                onClick={() => props.declareAnomaly()}
            />
        </MyTopToolbar>
    );
};

const formatDataForExport = (defectiveMaterial, _) => {
    return {
        [t('materials.material')]: defectiveMaterial.material.name,
        [t('defective-materials.code')]: `#${defectiveMaterial.materialId.slice(0, 4)}`,
        [t('locations.location')]: defectiveMaterial.origin.name,
        [t('defective-materials.state')]: t(`defective-materials.${defectiveMaterial.state}`),
        [t('defective-materials.comment')]: defectiveMaterial.comment,
        [t(
            'movements.agent'
        )]: `${defectiveMaterial.movement.user.firstname} ${defectiveMaterial.movement.user.lastname}`,
        [t('layout.date')]: defectiveMaterial.createdAt
    };
};

const MaintenanceFilter = props => (
    <Filter {...props} className='my-filters'>
        <SearchInput placeholder={t('maintenance.search')} source='search' alwaysOn className='my-search-field' />
        <RangeLimiter alwaysOn className='my-filter' />
        <SelectInput
            source='state'
            label={t('defective-materials.state')}
            alwaysOn
            choices={arrayToChoices(DEFECTIVE_MATERIAL_STATES, 'defective-materials')}
            className='my-filter end'
        />
    </Filter>
);

const MaterialIdField = props => {
    const record = useRecordContext(props);
    return (
        <span {...props} className={`text-field`}>
            {record.material.id ? `#${record.material.id.slice(0, 4)}` : '-'}
        </span>
    );
};

const StateField = props => {
    const record = useRecordContext(props);
    return (
        <span {...props} className={`text-field dm-state-text-field ${record.state}`}>
            {t(`defective-materials.${record.state}`)}
        </span>
    );
};

export const MaintenanceList = ({ ...props }) => {
    const [openEdit, setOpenEdit] = useState();
    const [editRecord, setEditRecord] = useState();
    const [openDeclareAnomaly, setOpenDeclareAnomaly] = useState(false);

    return (
        <Card>
            <MyList
                {...props}
                sort={{ field: 'createdAt', order: 'DESC' }}
                filters={<MaintenanceFilter />}
                actions={<MaintenanceActions {...props} declareAnomaly={() => setOpenDeclareAnomaly(true)} />}
                emptyDatagrid={t('layout.no-results')}
                empty={false}
            >
                <TextFieldPink source='origin.name' label={t('locations.location')} sortable />
                <FunctionField
                    source='material.name'
                    label={t('materials.material')}
                    render={record => <TextFieldImage source='name' record={record.material} />}
                    className='text-field terciary-text-field'
                    sortable
                />
                <MaterialIdField source='material.id' label={t('defective-materials.code')} sortable />
                <StateField source='state' label={t('defective-materials.state')} sortable />
                <FunctionField
                    className='action-field'
                    label={t('layout.actions')}
                    render={record =>
                        (record.state === 'TO_PROCESS' || record.state === 'MAINTENANCE') && (
                            <UpdateDefectiveMaterialButton
                                label={t('layout.actions')}
                                onClick={() => {
                                    setOpenEdit(true);
                                    setEditRecord(record);
                                }}
                            />
                        )
                    }
                />
                <FunctionField
                    className='action-field'
                    render={record => (
                        <ViewButton
                            icon='assets/send-icon.svg'
                            record={record}
                            res={record.type === 'specific' ? 'maintenanceMaterials' : 'defectiveMaterials'}
                        />
                    )}
                />
            </MyList>
            {editRecord && (
                <MyDialog open={openEdit} setOpen={() => setOpenEdit()} title={t('maintenance.edit-anomaly')} closeIcon>
                    {editRecord.type === 'specific' ? (
                        <MaintenanceMaterialEdit
                            basePath='/maintenanceMaterials'
                            resource='maintenanceMaterials'
                            id={editRecord.id}
                            closeDialog={() => setOpenEdit(false)}
                        />
                    ) : (
                        <DefectiveMaterialEdit
                            basePath='/defectiveMaterials'
                            resource='defectiveMaterials'
                            record={editRecord}
                            closeDialog={() => setOpenEdit(false)}
                        />
                    )}
                </MyDialog>
            )}
            <MaintenanceMaterialCreateDialog
                open={openDeclareAnomaly}
                setOpen={() => setOpenDeclareAnomaly()}
                closeIcon
            />
        </Card>
    );
};

const MainteanceInput = ({ label, type = 'text', disabled = true, value }) => {
    return (
        <div className='input'>
            <label>
                <span>{label}</span>
            </label>
            <input type={type} disabled={disabled} value={value} />
        </div>
    );
};

export const MaintenanceShow = props => {
    const model = useGetOne(props.resource, props.id);
    const { identity, loaded: identityLoaded } = useGetIdentity();
    const [openUploadDialog, setOpenUploadDialog] = useState(false);
    const [declaration, setDeclaration] = useState();
    const [loaded, setLoaded] = useState(false);
    const [update, { loading: updateLoading }] = useUpdate();
    const notify = useNotify();
    const refresh = useRefresh();

    const handleProcess = useCallback(async () => {
        update(
            props.resource + '/process',
            props.id,
            { process: true },
            { process: false },
            {
                onSuccess: () => {
                    setDeclaration({ ...declaration, state: 'MAINTENANCE' });
                    refresh();
                    notify(`${t('layout.changes-saved')}`, { undoable: false });
                },
                onFailure: error => notify(`Error: ${error.message}`, { type: 'warning' })
            }
        );
    }, [notify, refresh, update, props, declaration]);

    if (!declaration && model.loaded && identityLoaded) {
        const date = new Date(model.data.createdAt);
        const dateNb = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();

        // Build here the declaration object help to display the data
        // Declaration can be defectiveMaterial or maintenanceMaterial
        setDeclaration({
            ...model.data,
            material: {
                id: model.data.materialId,
                name: model.data.rawMaterial ? model.data.rawMaterial : model.data.material.name
            },
            origin: {
                id: model.data.originId,
                name: model.data.origin.name
            },
            user: {
                id: model.data.movement ? model.data.movement.user?.id : model.data.user?.id,
                firstname: model.data.movement ? model.data.movement.user?.firstname : model.data.user?.firstname,
                lastname: model.data.movement ? model.data.movement.user?.lastname : model.data.user?.lastname
            },
            handler:
                model.data.handler || model.data.personInCharge
                    ? {
                          id: model.data.personInCharge ? model.data.personInCharge?.id : model.data.handler?.id,
                          firstname: model.data.personInCharge
                              ? model.data.personInCharge?.firstname
                              : model.data.handler?.firstname,
                          lastname: model.data.personInCharge
                              ? model.data.personInCharge?.lastname
                              : model.data.handler?.lastname
                      }
                    : {
                          id: identity.id,
                          firstname: identity.firstname,
                          lastname: identity.lastname
                      },
            date: year + '-' + month + '-' + dateNb
        });
    }

    const today = new Date();

    if (!loaded && declaration && identityLoaded && !updateLoading) {
        setLoaded(true);
    }

    return (
        <div id='single-dm-page'>
            <PageHeader
                {...props}
                noBackground
                breadcrumbs={[
                    { label: t(`layout.maintenance`), path: '/maintenance' },
                    {
                        label: t('defective-materials.material-intervention-sheet') + '#' + props.id.slice(0, 8)
                    }
                ]}
            />
            {!loaded ? (
                <div className='loading-content'>
                    <h3>Chargement en cours de la fiche...</h3>
                </div>
            ) : (
                <div className='dm-content'>
                    {declaration.material.id && (
                        <MainteanceInput label={t('defective-materials.code')} value={'#' + declaration.material.id} />
                    )}
                    <MainteanceInput
                        label={t('defective-materials.material-reference')}
                        value={declaration.material.name}
                    />
                    <MainteanceInput
                        label={t('defective-materials.material-location')}
                        value={declaration.origin.name}
                    />
                    {declaration.local && (
                        <MainteanceInput label={t('defective-materials.local')} value={declaration.local} />
                    )}
                    {declaration.upload && (
                        <div className='show-photo'>
                            {declaration.upload.key.includes('pdf') ? (
                                <ButtonIcon
                                    src='assets/eye-icon-green.svg'
                                    value={t(`maintenance.show-pdf`)}
                                    onClick={() =>
                                        window.open(
                                            process.env.REACT_APP_S3_PUBLIC_URL + declaration.upload.key,
                                            '_blank'
                                        )
                                    }
                                />
                            ) : (
                                <ButtonIcon
                                    src='assets/eye-icon-green.svg'
                                    value={t(`defective-materials.show-photo`)}
                                    onClick={() => setOpenUploadDialog(true)}
                                />
                            )}
                        </div>
                    )}
                    <div className='agents'>
                        <div className='col'>
                            <h3>{t('defective-materials.requester')}</h3>
                            <MainteanceInput label={t('users.lastname')} value={declaration.user.lastname} />
                            <MainteanceInput label={t('users.firstname')} value={declaration.user.firstname} />
                            <MainteanceInput
                                type='date'
                                label={t('defective-materials.created-at')}
                                value={declaration.date}
                            />
                        </div>
                        <div className='col'>
                            <h3>{t('defective-materials.consideration-of-request')}</h3>
                            <MainteanceInput label={t('users.lastname')} value={declaration.handler.lastname} />
                            <MainteanceInput label={t('users.firstname')} value={declaration.handler.firstname} />
                            <MainteanceInput
                                type='date'
                                label={t('defective-materials.consideration-date')}
                                value={
                                    today.getFullYear() +
                                    '-' +
                                    (today.getMonth() + 1).toString().padStart(2, '0') +
                                    '-' +
                                    today.getDate().toString().padStart(2, '0')
                                }
                            />
                        </div>
                    </div>
                    <h3>{t('defective-materials.anomaly-title')}</h3>
                    <MainteanceInput
                        label={t('defective-materials.anomaly-type')}
                        value={t(`defective-materials.${declaration.anomalyType}`)}
                    />
                    <div className='comment'>
                        <h3>{t('defective-materials.comment-title')}</h3>
                        <textarea value={declaration.comment} disabled />
                    </div>
                    {declaration.state === 'TO_PROCESS' && (
                        <div className='send-button'>
                            <ButtonIcon
                                src='assets/send-icon-green.svg'
                                value={t('defective-materials.send-email')}
                                onClick={handleProcess}
                            />
                        </div>
                    )}
                    {declaration.upload && (
                        <DefectiveMaterialUploadDialog
                            open={openUploadDialog}
                            setOpen={() => setOpenUploadDialog()}
                            upload={declaration.upload}
                        />
                    )}
                </div>
            )}
        </div>
    );
};
