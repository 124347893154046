import { t } from 'i18next';
import { useCallback, useMemo, useState } from 'react';
import {
    SaveContextProvider,
    SelectInput,
    SimpleForm,
    required,
    useGetIdentity,
    useGetList,
    useNotify,
    useRefresh,
    useUpdate
} from 'react-admin';
import { DEFECTIVE_MATERIAL_STATES } from '../constants';
import '../style/components/defective-materials.scss';
import { arrayToChoices } from '../utils';
import { MySaveButton } from './react-admin-components/Buttons';
import { MyTextInput } from './react-admin-components/Inputs';

export const DefectiveMaterialEdit = ({ ...props }) => {
    const { loaded, identity } = useGetIdentity();
    const [update, { loading }] = useUpdate();
    const locations = useGetList('locations', { page: 1, perPage: 1000 }, { field: 'name', order: 'ASC' });
    const [state, setState] = useState();
    const refresh = useRefresh();
    const notify = useNotify();

    const handleSave = useCallback(
        async values => {
            update('defectiveMaterials', props.record.id, values, identity, {
                onSuccess: () => {
                    props.closeDialog();
                    refresh();
                    notify(`${t('layout.changes-saved')}`, { undoable: false });
                },
                onFailure: error => notify(`Error: ${error.message}`, { type: 'warning' })
            });
        },
        [notify, refresh, update, identity, props]
    );

    const saveContext = useMemo(
        () => ({
            save: handleSave,
            loading
        }),
        [loading, handleSave]
    );

    if (!locations.loaded || !loaded || loading) return null;
    const choices = locations.ids.map(id => {
        return { id: id, name: locations.data[id].name };
    });

    return (
        <SaveContextProvider {...props} className='create-form' value={saveContext}>
            <SimpleForm
                {...props}
                toolbar={<MySaveButton label={t('layout.validate-form')} />}
                submitOnEnter={false}
                record={props.record}
                save={handleSave}
            >
                <MyTextInput
                    source='material.name'
                    labelUnderInput
                    label={t('materials.reference')}
                    placeholder={t('locations.location-placeholder')}
                    validate={required()}
                />
                <div className='row'>
                    <SelectInput
                        source='state'
                        onChange={e => setState(e.target.value)}
                        className='input select label-under-input'
                        choices={arrayToChoices(DEFECTIVE_MATERIAL_STATES, 'defective-materials')}
                        validate={required()}
                        label={t('defective-materials.state')}
                    />
                    {state === 'REPAIRED' && (
                        <SelectInput
                            source='destinationId'
                            className='input select label-under-input'
                            choices={choices}
                            validate={required()}
                            label={t('locations.location')}
                        />
                    )}
                </div>
            </SimpleForm>
        </SaveContextProvider>
    );
};
